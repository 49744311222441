import Alpine from 'alpinejs'
import {init as tagmanagerInit} from './abstracts/tagmanager'
import {init as cookieConsentInit} from './abstracts/cookie-consent'
import focus from '@alpinejs/focus'
import morph from '@alpinejs/morph'
import intersect from '@alpinejs/intersect'

import './config/ads'
import cookieBanner from './components/cookieBanner'
import contentMenu from './components/contentMenu'
import lightbox from './components/common/lightbox'
import slider from './components/splide/slider'
import header from './components/header/header'
import track from './components/track' //native javascript tracking
import tracking from './components/common/track' //apline js tracking
import dateRange from './components/form/datepicker/dateRange'
import formInput from './components/form/input'
import formAutocomplete from './components/form/autocompleter'
import formAutocompleteJson from './components/form/autocompleterJson'
import button from './components/common/button'
import pagination from './components/common/pagination'
import promotionList from './components/promotion/list'
import formSearchAccommodation from './components/form/search/accommodation'
import formSearchSkiresort from './components/form/search/skiresort'
import formAutocompleteWithResource from './components/form/autocompleterWithResource'
import formSearchSummerregion from './components/form/search/summerregion'
import formSearchWebcam from './components/form/search/webcam'
import formSearchWeather from './components/form/search/weather'
import {preloadBody} from './components/common/preload'
import tooltip from './binds/tooltip'
import modalTrigger from './binds/modalTrigger'
import headerAutocompleter from './components/header/autocompleter'
import modal from './components/common/modal'
import modalAjax from './components/common/modalAjax'
import dynamicBackgroundSwap from './components/common/dynamicBackgroundSwap'
import stickyBanner from './components/banner/sticky'
import contentAds from './components/promotion/contentAds'
import sunnmoon from './components/content/weather/sunmoon'
import user from './stores/user'
import map from './stores/map'
import storeHeader from './stores/header'
import modalStore from './stores/modal'
import loadJs from './directives/load-js'
import favorites from './components/common/favorites'
import formValidator from './components/form/formValidator'
import newsletter from './components/common/newsletter'
import report from './components/common/report'
import fillupAds from './components/promotion/fillupAds'
import notify from './magics/notify.js'
import notification from './stores/notification.js'

window.Alpine = Alpine

tagmanagerInit()
cookieConsentInit()
preloadBody()

// tagmanager tracking
track()

// components
cookieBanner()
contentMenu()
lightbox()
contentAds()

// plugins
Alpine.plugin(morph)
Alpine.plugin(focus)
Alpine.plugin(intersect)

// alpine data
Alpine.data('datepickerRange', dateRange)
Alpine.data('formInput', (data) => formInput(data))
Alpine.data('formAutocomplete', (data) => formAutocomplete(data))
Alpine.data('formAutocompleteJson', (data) => formAutocompleteJson(data))
Alpine.data('formAutocompleteWithResource', (data) => formAutocompleteWithResource(data))
Alpine.data('formSearchAccommodation', (action) => formSearchAccommodation(action))
Alpine.data('formSearchSkiresort', formSearchSkiresort)
Alpine.data('formSearchSummerregion', formSearchSummerregion)
Alpine.data('formSearchWebcam', formSearchWebcam)
Alpine.data('formSearchWeather', formSearchWeather)
Alpine.data('button', button)
Alpine.data('pagination', pagination)
Alpine.data('promotionList', (data) => promotionList(data))
Alpine.data('header', header)
Alpine.data('headerAutocomplete', (data) => headerAutocompleter(data))
Alpine.data('modal', modal)
Alpine.data('modalAjax', modalAjax)
Alpine.data('dynamicBackgroundSwap', dynamicBackgroundSwap)
Alpine.data('stickyBanner', stickyBanner)
Alpine.data('slider', slider)
Alpine.data('favorites', favorites)
Alpine.data('formValidator', formValidator)
Alpine.data('newsletter', newsletter)
Alpine.data('tracking', tracking)
Alpine.data('report', report)
Alpine.data('fillupAds', fillupAds)
Alpine.data('sunmoon', sunnmoon)

// alpine store
Alpine.store('user', user)
Alpine.store('map', map)
Alpine.store('header', storeHeader)
Alpine.store('modal', modalStore)
Alpine.store('notification', notification)

// alpine bind
Alpine.bind('tooltip', (text) => tooltip(text))
Alpine.bind('modalTrigger', (id, endpoint, trackingEven, trackingCategory) => modalTrigger(id, endpoint, trackingEven, trackingCategory))

// alpine directives
Alpine.directive('load-js', loadJs)

// alpine magics
Alpine.magic('notify', notify)

Alpine.start()
