const sunmoon = () => ({
    transitionTimeMS: 300,

    initComponent(value) {
        if (!value) {
            return
        }

        this.animateSun(parseFloat(value))
        this.maybeHideStartEnd(parseFloat(value))
    },

    animateSun(value) {
        this.$refs.sun.style.transform = `rotate(-${170 * value}deg)`
        this.$refs.yellowArchMask.style.transform = `translateX(-${value * 100}%)`
    },

    maybeHideStartEnd(value) {
        if (value >= 0.03) {
            this.$refs.circleStart.classList.remove('tw-hidden')
        }

        if (value === 1) {
            setTimeout(() => this.$refs.circleEnd.classList.add('tw-hidden'), transitionTimeMS)
        }
    },
})

export default sunmoon
